<template>
    <v-card :disabled="internalLoading || loading || disabled" :loading="(internalLoading || loading) ? 'secondary' : false"
            outlined>
        <v-card-title>{{ $t("csv.import") }}</v-card-title>
        <v-card-text>

            <v-alert
                v-for="error in alerts"
                :key="error"
                :type="error.type"
                border="left"
                dismissible
            >
                {{ error.text }}
            </v-alert>

            <FileUpload v-if="file === null" :accept="accept" v-on:file="uploadFile"></FileUpload>
            <div v-else>
                <v-card-title>
                    <v-icon>mdi-file-delimited</v-icon>
                    {{ file.name }}
                </v-card-title>
                <v-row>
                    <v-col cols="6">
                        <v-checkbox v-model="hasHeader" :label="$t('csv.headers')"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="delimiter" :label="$t('csv.delimiter')" outlined></v-text-field>
                    </v-col>
                </v-row>

                <v-simple-table v-if="lines.length > 0">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">#</th>
                            <th v-for="i in lines[0].length" :key="i" class="text-left pt-2">
                                <v-combobox v-model="selectedHeaders[i - 1]" :items="headers" :label="$t('csv.column')"
                                            dense outlined></v-combobox>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(row, index) in lines.slice(0, 10)"
                            :key="row"
                        >
                            <td>{{ index + 1 }}</td>
                            <td v-for="col in row" :key="col">{{ col }}</td>
                        </tr>
                        <tr v-if="lines.length > 10">
                            <td colspan="3">{{ $t("csv.more_lines", {lines: lines.length - 10}) }}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="error"
                text
                @click="cancel"
                :disabled="disabled"
                :loading="internalLoading || loading"
            >
                {{ $t("csv.cancel") }}
            </v-btn>
            <v-btn
                v-if="lines.length > 0"
                color="secondary"
                text
                @click="importFile"
                :disabled="disabled"
                :loading="internalLoading || loading"
            >
                {{ $t("csv.import") }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import FileUpload from "@/components/FileUpload.vue";

export default {
    name: "CsvImporter",
    components: {FileUpload},
    props: ["headers", "useAllHeaders", "disabled", "loading"],
    data() {
        return {
            file: null,
            accept: [
                "text/csv"
            ],
            hasHeader: true,
            delimiter: ",",
            lines: [],
            internalLoading: false,
            selectedHeaders: [],
            alerts: []
        };
    },
    methods: {
        uploadFile(ev) {
            if (!this.accept.includes(ev.type)) {
                return;
            }

            this.file = ev;
            this.parseCsv();
        },
        parseCsv() {
            this.internalLoading = true;
            const reader = new FileReader();
            reader.onloadend = e => {
                const data = e.target.result;
                this.lines = [];
                let ls = data.split("\n");
                if (this.hasHeader) {
                    ls.shift();
                }
                for (const line of ls) {
                    this.lines.push(line.split(this.delimiter));
                }
                this.internalLoading = false;
            };
            reader.readAsText(this.file);
        },
        importFile() {
            this.alerts = [];
            if (this.useAllHeaders === undefined || this.useAllHeaders === true) {
                for (const header of this.headers) {
                    if (!this.selectedHeaders.includes(header)) {
                        this.alerts = [{
                            type: "error",
                            text: this.$t("csv.missing_headers")
                        }];
                        return;
                    }
                }
            }

            let parsed = [];
            for (const line of this.lines) {
                let obj = {};
                for (const selectedHeadersKey in this.selectedHeaders) {
                    obj[this.selectedHeaders[selectedHeadersKey]] = line[selectedHeadersKey];
                }
                parsed.push(obj);
            }
            this.$emit("data", parsed);
        },
        cancel() {
            this.file = null;
            this.$emit("cancel");
        }
    },
    watch: {
        delimiter(newVal) {
            if (newVal.length > 0) {
                this.parseCsv();
            }
        },
        hasHeader() {
            this.parseCsv();
        }
    }
};
</script>

<style scoped>

</style>