<template>
    <form enctype="multipart/form-data" novalidate>
        <div class="dropbox">
            <input :accept="acceptStr" class="input-file" multiple
                   name="file"
                   type="file"
                   @change="filesChange($event.target.files); fileCount = $event.target.files.length">
            <p>
                {{ $t("attachments.drag") }}<br> {{ $t("attachments.click") }}
            </p>
        </div>
    </form>
</template>

<script>

import { isString } from "lodash";

export default {
    name: "FileUpload",
    props: ["accept"],
    data() {
        return {};
    },
    methods: {
        filesChange(fileList) {
            if (!fileList.length) return;

            for (let i = 0; i < fileList.length; i++) {
                const file = fileList[i];

                this.$emit("file", file);
            }
        }
    },
    computed: {
        acceptStr() {
            if (isString(this.accept)) {
                return this.accept.toString();
            }
            return this.accept.join(",");
        }
    }
};
</script>

<style scoped>
.app--dark .dropbox {
    outline: 2px dashed #d0d0d0;
    background: #363636;
    color: #d0d0d0;
}

.app--dark .dropbox:hover {
    background: #505050;
}

.dropbox {
    outline: 2px dashed #363636; /* the dash box */
    outline-offset: -10px;
    background: #f6f6f6;
    color: #363636;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
    border-radius: 4px;
}

.input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
}

.dropbox:hover {
    background: #e0e0e0;
}

.dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
}
</style>
